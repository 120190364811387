import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Img from "../../components/Elements/Img"
import SectionContainer from "../../components/SectionContainer"
import VideoPlayer from "../../components/VideoPlayer/index"
import { Container, Row, Col } from "../../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../../components/Elements/index"

import gifImage from '../../images/2024/xeomin/image12.gif';

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const xeomin = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#707070",
        dividerColor: "#3F96CB;",
        invert: {
          backgroundColor: "#00386C",
          dividerColor: "#ffffff;",
        },
      }}
    >
      <>
        <Seo title="Xeomin | Fight of a lifetime" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  <div style={{ color: "#1D4289"}}>The Work</div>
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px", color: "#1D4289" }}>
              <PageTitle>Xeomin: Fight of a lifetime</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>
                  <div style={{ color: "#1D4289"}}>
                    The Problem
                  </div>
                </AccentHeader>                
                <SectionBody size="regular">
                  Patients with lifelong movement disorders require long-term therapy and are often prescribed a treatment with additives. Over time, the body gets used to these unnecessary additives and the benefits of treatment diminish greatly. This is called waning efficacy, and it’s a serious challenge that HCPs face in treating lifelong <nobr>movement disorders.</nobr><br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1 style={{ color: "#1D4289"}}>In the fight against lifelong movement disorders, 
patients need a treatment that’s as resilient as they are.</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>


        <SectionContainer noBottomMargin noTopMargin>
          <RevomeMarginWrapper noTopMargin noBottomMargin>
            <VideoPlayer
              poster={() => <Img fluid={data.videoThumbnail1.childImageSharp.fluid} />}
              url="https://player.vimeo.com/video/908302451?h=a8775bd949&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            />
          </RevomeMarginWrapper>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#888786" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2 style={{ color: "#00386C" }}>The Opportunity</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  More than 40 million people in the United States suffer from movement disorders, and many of them are on lifelong treatment. Most available treatments come with additives that the body builds resistance to, leaving patients with no choice but to wait years for their body to lose the immunity it has naturally built up. <br/><br/> XEOMIN doesn’t have unnecessary additives, which means it will work even if the body has developed antibodies to another toxin, making it the ideal choice for long-term treatment and durable efficacy.</SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <div style={{marginBottom: "15px"}}><Img fluid={data.image6.childImageSharp.fluid} /></div>
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
        <Row>
              <Col md={12}>
                <RevomeMarginImage>
                  <div style={{marginBottom: "15px"}}><Img fluid={data.image7.childImageSharp.fluid} /></div>
                </RevomeMarginImage>
              </Col>
        </Row>
        </SectionContainer>

        <SectionContainer noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#888786" }}>
            <Row>
              <Col md={12}>                
                <AccentHeader>
                <h2 style={{ color: "#00386C" }}>The Idea</h2>                
                </AccentHeader>                

                <SectionBody size="regular">
                  <h1 style={{ color: "#233c7a" }}>The Fight of a Lifetime campaign reframes the physical manifestations of movement disorders as scenes of a personal fight: clenched fists become symbols of strength, strained muscles suggest resilience, and excessive blinking is a momentary flinch as we highlight moments of the fight.</h1>
                </SectionBody>                
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noBottomMargin noTopMargin>          
          <Img fluid={data.image5.childImageSharp.fluid} />
        </SectionContainer>

        <SectionContainer noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "30px", paddingBottom: "30px", color: "#888786" }}>
            <Row>
              <Col md={12}>                
                <SectionBody size="regular">
                  Using black and white photography, we capture the emotional depth and raw humanity of 6 individuals fighting their own disease. In a series of portraits, we strip away distractions of color and background detail to focus attention on the essence of the fighter—a detailed study of not just the person, but highlighting the journey, the struggle, and the resilience. Black and white also allows for an exploration into duality and contradiction. Light and dark. Strength and vulnerability. They are human, but they are strong. The contours of resilience and despair exist against the hope within, underscoring a deeply rooted defiance against their disease.<br/><br/>
                  By simplifying the image, texture and form come to life in every expression, building drama through the authentic lives of our heroes. Laugh lines, crow’s feet, wrinkles, and scars invite the observer to ask a question—a silent conversation with the subject that unfolds a beautiful inner world amid an intense and ongoing battle against their condition.<br/><br/>
                  Not exploitative, we examine moments of struggle and relief while keeping the human front and center. It’s a powerful form of visual storytelling whose messages reach beyond disease state and into the universal themes of endurance, courage, and the indomitable human spirit. The photographer evokes empathy, awareness, and respect for anyone fighting movement disorders—and above all, celebrate the everyday demonstrations of resilience humans fighting for their life against movement disorders embody.

          
                </SectionBody>
              </Col>
              <Col md={12}>
                <SectionBody size="regular">
                  <h1 style={{ color: "#005EA1" }}>In the fight against movement disorders, XEOMIN Goes the Distance.</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>
        

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <div style={{marginBottom: "15px"}}><Img fluid={data.image2.childImageSharp.fluid} /></div>
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
        <Row>
              <Col md={4}>
                <RevomeMarginImage>
                  <Img fluid={data.image3.childImageSharp.fluid} />
                </RevomeMarginImage>
              </Col>
              <Col md={4}>
                <RevomeMarginImage>
                  <Img fluid={data.image4.childImageSharp.fluid} />
                </RevomeMarginImage>
              </Col>
        </Row>
          
        </SectionContainer>

        <SectionContainer noBottomMargin noTopMargin>          
          <div style={{ marginTop: "-17px"}}>
            <RevomeMarginImage><Img fluid={data.image9.childImageSharp.fluid} /></RevomeMarginImage>
            <RevomeMarginImage><Img fluid={data.image10.childImageSharp.fluid} /></RevomeMarginImage>
             <img src={gifImage} alt="GIF Image" />
          </div>
        </SectionContainer>


        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#ffffff" }}>
            
            <Row>
              <Col md={4}>
              <AccentHeader>                  
                  <h2 style={{ color: "#ffffff" }}>The Outcome</h2>
                </AccentHeader> 
                <SectionBody size="regular">
                  <div style={{paddingRight: "60px"}}>
                    The thoughtful and thought-provoking nature of this campaign caused a stir across the industry. With XEOMIN sales growing at three times the rate of the market. The conversation around movement disorders has shifted as more patients embrace their inner fighter with XEOMIN. And HCPs now finally have a therapy they can count on, and their patients have a partner who’ll never leave their corner.
                  </div>
                </SectionBody>
              </Col>              
              <Col md={4}>
                <SectionBody size="regular">
                <AccentHeader>
                </AccentHeader> 
                  <h1 style={{ color: "#ffffff" }}>XEOMIN sales growth at 3x the rate of the market following the launch of this boundary-breaking work.</h1>
                </SectionBody>
              </Col>              
            </Row>            
          </Container>
        </SectionContainer>  

              </>
    </ThemeProvider>
  </Layout>
)

export default xeomin

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2024/xeomin/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2024/xeomin/image2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image3: file(relativePath: { eq: "2024/xeomin/image3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image4: file(relativePath: { eq: "2024/xeomin/image4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image5: file(relativePath: { eq: "2024/xeomin/image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image6: file(relativePath: { eq: "2024/xeomin/image6.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image7: file(relativePath: { eq: "2024/xeomin/image7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image8: file(relativePath: { eq: "2024/xeomin/image8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image9: file(relativePath: { eq: "2024/xeomin/image9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image10: file(relativePath: { eq: "2024/xeomin/image10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image11: file(relativePath: { eq: "2024/xeomin/image11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    videoThumbnail1: file(relativePath: { eq: "2024/xeomin/videoposter.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
